<template>
  <div class="cart-checkout">
    <!-- checkout:info+button START -->
    <h1
      class="headline"
      v-html="$t('terminal.cart.checkout.headline')"
      v-if="!loadingVisibleDialog"
    ></h1>
    <div class="divider" v-if="!loadingVisibleDialog"></div>
    <button
      v-if="!loadingVisibleDialog"
      class="btn-inverted btn-checkout"
      v-on:click="abort()"
      v-on:touchstart="touchStart($event)"
      v-on:touchend="touchEnd($event);"
      v-on:touchcancel="touchEnd($event)"
      v-html="$t('terminal.cart.buttons.abort')"
    ></button>
    <div class="note" v-if="!loadingVisibleDialog"></div>
    <!-- checkout:info+button END -->

    <!-- checkout:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- checkout:loading END -->

    <!-- delete:modal START -->
    <dialog-confirm
      :loadingVisible="loadingVisibleDialog"
      :headline="loadingHeadlineDialog"
      :excerpt="loadingExcerptDialog"
      :open="openDialog"
      :close="closeDialog"
      :confirm="confirmDialog"
    />
    <!-- delete:modal END -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'cart-checkout',
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
    DialogConfirm: () => import('../../components/shared/dialog/Confirm.vue'),
  },
  data() {
    return {
      scannedString: '',

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('terminal.loading.checkout.headline'),
      loadingExcerpt: this.$t('terminal.loading.checkout.description'),
      /**
       * Dialog
       */
      loadingVisibleDialog: true,
      loadingHeadlineDialog: '',
      loadingExcerptDialog: '',

      invoice: false,
    };
  },
  created() {
    const user = JSON.parse(localStorage.getItem('user'));
    const { hasPayment } = user.user;

    if (Boolean(this.$route.query.ecCard) === true
      && Boolean(this.$route.query.qrcodeActive === false)) {
      // create order
      this.createOrder();
      // redirect to thank you screen
      setTimeout(() => {
        this.$router.push('/cart/thank-you');
      }, 125);
    }
    if (Boolean(this.$route.query.ecCard) !== true) {
      if (hasPayment === 1) {
        // create order
        this.createOrder();
        // redirect to thank you screen
        setTimeout(() => {
          this.$router.push('/cart/thank-you-app');
        }, 125);
      }
    }

    window.addEventListener('keydown', this.keyDownHandler);
  },
  computed: {
    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }
      return this.$store.state.storeCart;
    },
  },
  methods: {
    ...mapActions([
      'createOrder',
      'clearCart',
    ]),

    keyDownHandler(e) {
      const keyCode = e.keyCode || e.which;
      const code = e.code || '';
      let char = e.key;

      if (keyCode === 89 && code === 'KeyY' && char === 'Y') char = 'Z';
      else if (keyCode === 89 && code === 'KeyY' && char === 'y') char = 'z';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'Z') char = 'Y';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'z') char = 'y';

      if (char.length > 1) char = '';
      this.scannedString += char;

      if (e.keyCode === 13) {
        // this.loadingVisible = true;
        this.createOrder();
        setTimeout(() => {
          this.loadingVisible = false;
          this.scannedString = '';
          if (this.invoice === true) {
            this.$router.push({ path: '/cart/thank-you', query: { invoice: true } });
          } else {
            this.$router.push({ path: '/cart/thank-you', query: { invoice: false } });
          }
          this.$router.push('/cart/thank-you');
        }, 350);
      }
    },

    abort() {
      // reset store cart
      this.clearCart();
      // redirect to home
      this.$router.push('/');
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    openDialog() {
      this.loadingVisibleDialog = true;
    },

    closeDialog() {
      this.invoice = false;
      this.loadingVisibleDialog = false;
      setTimeout(() => {
        const note = document.querySelector('.note');
        if (note) {
          note.classList.add('loaded');
        }
      }, 350);
    },

    confirmDialog() {
      this.invoice = true;
      this.loadingVisibleDialog = false;
      setTimeout(() => {
        const note = document.querySelector('.note');
        if (note) {
          note.classList.add('loaded');
        }
      }, 350);
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },
};
</script>
